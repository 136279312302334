import React, { useEffect, useState } from "react"
import { Button, Col, Collapse, Divider, Row } from "antd"
import { CloseCircleFilled } from "@ant-design/icons"
import moment from "moment"
import { useQuery } from "@apollo/client"

import TaxesAndServiceDetails from "./Modals/TaxesAndServiceDetails"
import PromoForm from "./PromoForm"

import { dateFormatBooking } from "../FindaHotel/initailValue"
import { InformationIcon, EditPenIcon } from "../../utilities/icons/icons"
import { GET_PROMO_CODES } from "./graphql"
import { currencyFormatter } from "../../utilities/format-currency"
import "./BookingDetails.scss"

const { Panel } = Collapse

const BookingSummary = ({
  activePanel,
  addOnsList,
  currentStep,
  dayCount,
  datePickerHotel,
  partyArray,
  rateSelectedOnRooms,
  roomDataInquiry,
  rooms,
  roomsAndRatesWithTax,
  setActivePanel,
  setActiveRoom,
  setAddOnsList,
  setCurrentStep,
  setPromoSubmit,
  setRoomsAndRatesWithTax,
  setTotalAmount,
  setSelectedPromo,
  totalAmount,
}) => {
  const [addOnsAmount, setAddOnsAmount] = useState<number>(0)
  const [addOnsTax, setAddOnsTax] = useState<number>(0)
  const [discountAmount, setDiscountAmount] = useState<number>(0)
  const [discountedRoomsTax, setDiscountedRoomsTax] = useState<[any] | null>(
    null
  )
  const [isPromoValidated, setIsPromoValidated] = useState<boolean>(false)
  const [roomsAndRatesAmount, setRoomsAndRatesAmount] = useState<number>(0)
  const [roomsAndRatesTaxOnly, setRoomsAndRatesTaxOnly] = useState<number>(0)
  const [showTaxesAndService, setShowTaxesAndService] = useState<boolean>(false)
  const [subtotalAmount, setSubtotalAmount] = useState<number>(0)
  const [taxAmount, setTaxAmount] = useState<number>(0)

  const partnerDataInput = rateSelectedOnRooms
    .filter(item => item !== undefined)
    .filter(item => item !== null)
    .map((item, index) => {
      return {
        room_id: partyArray[index].room_id.toString(),
        partner_data_id: item?.partnerDataID,
      }
    })

  const partyInput = partyArray.map(party => {
    return {
      adults: party.adults,
      children: party.children,
    }
  })

  const promoCodeInput = {
    ...roomDataInquiry,
    final_price_at_booking: { amount: 0 },
    final_price_at_checkout: { amount: roomsAndRatesWithTax },
    party: partyInput,
    partner_data: partnerDataInput,
  }

  const promoCodesQuery = useQuery(GET_PROMO_CODES, {
    variables: {
      input: promoCodeInput,
    },
  })

  const handleEditRoom = key => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setCurrentStep(0)
    setActiveRoom(key)
  }

  const handleEditAddOns = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setCurrentStep(1)
  }

  const removeAddOnItem = key => {
    addOnsList.map((item, index) => {
      if (index === key) {
        return (item.selected = false)
      }
    })
    setAddOnsList([...addOnsList])
  }

  useEffect(() => {
    setRoomsAndRatesAmount(
      rateSelectedOnRooms.reduce((total, value) => {
        const ratePrice = value ? value.amount : 0
        return total + ratePrice
      }, 0)
    )
    setRoomsAndRatesWithTax(
      rateSelectedOnRooms.reduce((total, value) => {
        const ratePrice = value ? value.amountWithTax : 0
        return total + ratePrice
      }, 0)
    )
    !isPromoValidated &&
      setRoomsAndRatesTaxOnly(
        rateSelectedOnRooms.reduce((total, value) => {
          const taxPrice = value ? value.tax : 0
          return total + taxPrice
        }, 0)
      )
    setAddOnsAmount(
      addOnsList.reduce((total, value) => {
        const addOnPrice = value.selected ? value.net_rates * value.counter : 0
        return total + addOnPrice
      }, 0)
    )
    setAddOnsTax(
      addOnsList.reduce((total, value) => {
        const addOnTax = value.selected ? value.tax * value.counter : 0
        return total + addOnTax
      }, 0)
    )
  }, [rateSelectedOnRooms, addOnsList])

  useEffect(() => {
    setSubtotalAmount(addOnsAmount + roomsAndRatesAmount)
  }, [addOnsAmount, roomsAndRatesAmount])

  useEffect(() => {
    setTaxAmount(roomsAndRatesTaxOnly + addOnsTax)
  }, [roomsAndRatesTaxOnly, addOnsTax])

  useEffect(() => {
    setTotalAmount(subtotalAmount + taxAmount - discountAmount)
  }, [discountAmount, subtotalAmount, taxAmount])

  const addOnsSummary = addOnsList.map((item, index) => {
    return item.selected ? (
      <div className="booking-summary-details-cont" key={index}>
        <span className="booking-summary-variable">
          {`${item.name} (x${item.counter})`}
        </span>
        <span className="booking-summary-value">
          {`Php ${item.net_rates * item.counter}.00 `}
          <CloseCircleFilled onClick={() => removeAddOnItem(index)} />
        </span>
      </div>
    ) : null
  })

  const handlePanelChange = e => {
    setActivePanel(e)
  }

  const noAddOnsSummary = (
    <span className="booking-summary-none">No Selected Add-Ons</span>
  )
  const checkAddOns =
    addOnsSummary.filter(item => item !== null).length === 0 ? false : true

  return (
    <>
      <div className="booking-summary-wrapper">
        <h5 className="booking-summary-title">Booking Summary</h5>
        <div className="booking-summary-cont">
          <div className="booking-summary-details-cont">
            <div className="booking-summary-variable">Check-In</div>
            <div className="booking-summary-value">
              {moment(datePickerHotel?.[0]).format(dateFormatBooking)}
            </div>
          </div>
          <div className="booking-summary-details-cont">
            <div className="booking-summary-variable">Check-Out</div>
            <div className="booking-summary-value">
              {moment(datePickerHotel?.[1]).format(dateFormatBooking)}
            </div>
          </div>
        </div>
        <div className="booking-summary-collapsible-wrapper">
          <Collapse
            expandIconPosition="right"
            activeKey={activePanel}
            onChange={handlePanelChange}
          >
            <Panel
              header="ROOMS & RATES"
              extra={currencyFormatter(roomsAndRatesAmount, {
                significantDigits: 2,
                symbol: "Php",
              })}
              key="1"
            >
              {rooms.map((room, key) => (
                <div key={key}>
                  <div>
                    <div className="booking-summary-details-cont">
                      <div className="booking-summary-variable">
                        {`${room.label} ${key + 1} `}
                        <Button
                          type="text"
                          className="edit-txt-btn"
                          style={{
                            color: rateSelectedOnRooms[key]
                              ? "#d82229"
                              : "#bdcadb",
                          }}
                          onClick={() => handleEditRoom(key + 1)}
                        >
                          <EditPenIcon />
                          Edit
                        </Button>
                      </div>
                      <div className="booking-summary-value">
                        {rateSelectedOnRooms[key] ? (
                          rateSelectedOnRooms[key].roomName
                        ) : (
                          <span className="booking-summary-none">
                            No Selected Room Yet
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="booking-summary-details-cont">
                      <div className="booking-summary-variable">
                        Rate Selected
                      </div>
                      <div className="booking-summary-value">
                        {rateSelectedOnRooms[key] ? (
                          rateSelectedOnRooms[key].rateName
                        ) : (
                          <span className="booking-summary-none">
                            No Selected Rate
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="booking-summary-details-cont">
                      <div className="booking-summary-variable">Guest/s</div>
                      <div className="booking-summary-value">
                        {room.adult > 1
                          ? `${room.adult} Adults`
                          : `${room.adult} Adult`}
                        ,{" "}
                        {room.children > 1
                          ? `${room.children} Children`
                          : `${room.children} Child`}
                      </div>
                    </div>
                    <div className="booking-summary-details-cont">
                      <div className="booking-summary-variable">Amount</div>
                      <div className="booking-summary-value">
                        {rateSelectedOnRooms[key]
                          ? currencyFormatter(rateSelectedOnRooms[key].amount, {
                              significantDigits: 2,
                              symbol: "Php",
                            })
                          : `Php 0.00`}
                      </div>
                    </div>
                  </div>
                  {rooms.length !== key + 1 && <Divider />}
                </div>
              ))}
            </Panel>
            <Panel
              header={
                <div className="add-ons-header">
                  <span>ADD-ONS</span>
                  <span
                    className="edit-txt-btn"
                    style={{
                      color: "#d82229",
                      padding: 0,
                      marginLeft: "10px",
                    }}
                    onClick={handleEditAddOns}
                  >
                    Edit
                  </span>
                </div>
              }
              extra={currencyFormatter(addOnsAmount, {
                significantDigits: 2,
                symbol: "Php",
              })}
              key="2"
            >
              {checkAddOns ? addOnsSummary : noAddOnsSummary}
            </Panel>
            <Panel header="PROMO CODE" key="3">
              <PromoForm
                isPromoValidated={isPromoValidated}
                dayCount={dayCount}
                promoCodeInput={promoCodeInput}
                promoCodesQuery={promoCodesQuery}
                rateSelectedOnRooms={rateSelectedOnRooms}
                setDiscountAmount={setDiscountAmount}
                setDiscountedRoomsTax={setDiscountedRoomsTax}
                setIsPromoValidated={setIsPromoValidated}
                setPromoSubmit={setPromoSubmit}
                setRoomsAndRatesTaxOnly={setRoomsAndRatesTaxOnly}
                setSelectedPromo={setSelectedPromo}
              />
            </Panel>
          </Collapse>
        </div>
        <div className="booking-summary-misc-wrapper">
          <div className="booking-summary-cont border-bottom">
            <div className="booking-summary-details-cont">
              <div className="booking-summary-variable">Subtotal</div>
              <div className="booking-summary-value">
                {currencyFormatter(subtotalAmount, {
                  significantDigits: 2,
                  symbol: "Php",
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="booking-summary-misc-wrapper">
          <div className="booking-summary-cont border-bottom">
            <div className="booking-summary-details-cont">
              <div className="booking-summary-variable">Discount</div>
              <div className="booking-summary-value">
                -{" "}
                {currencyFormatter(discountAmount, {
                  significantDigits: 2,
                  symbol: "Php",
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="booking-summary-misc-wrapper">
          <div className="booking-summary-cont">
            <div className="booking-summary-details-cont">
              <div className="booking-summary-variable">
                <span>Taxes and Service Charge</span>{" "}
                <InformationIcon onClick={() => setShowTaxesAndService(true)} />
              </div>
              <div className="booking-summary-value">
                {currencyFormatter(taxAmount, {
                  significantDigits: 2,
                  symbol: "Php",
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="booking-summary-grand-total-wrapper">
          <div className="booking-summary-cont">
            <div className="booking-summary-details-cont">
              <div>Total Amount:</div>
              <div>
                {currencyFormatter(totalAmount, {
                  significantDigits: 2,
                  symbol: "Php",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TaxesAndServiceDetails
        addOnsList={addOnsList}
        discountedRoomsTax={discountedRoomsTax}
        onCancel={() => setShowTaxesAndService(false)}
        rateSelectedOnRooms={rateSelectedOnRooms}
        visible={showTaxesAndService}
      />
    </>
  )
}

export default BookingSummary
