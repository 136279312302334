import React from "react"
import { Divider, Modal } from "antd"

import "../BookingDetails.scss"
import { currencyFormatter } from "../../../utilities/format-currency"

interface PropsI {
  addOnsList: any[]
  discountedRoomsTax: [any] | null
  rateSelectedOnRooms: any[]
  visible: boolean
  onCancel?: () => void
}

const TaxesAndServiceDetails = ({
  addOnsList,
  discountedRoomsTax,
  rateSelectedOnRooms,
  visible,
  onCancel,
}: PropsI) => {
  const roomsTaxList = rateSelectedOnRooms
    .filter(item => item !== null)
    .map((item, index) => {
      const amount = discountedRoomsTax
        ? discountedRoomsTax[index].vat
        : item.vat

      return (
        <li key={index}>
          <span className="tax-list-item">
            Room {index + 1} - {item?.roomName}
          </span>
          <span>
            {currencyFormatter(amount, {
              significantDigits: 2,
              symbol: "Php",
            })}
          </span>
        </li>
      )
    })

  const roomsServiceCharge = rateSelectedOnRooms
    .filter(item => item !== null)
    .map((item, index) => {
      const amount = discountedRoomsTax
        ? discountedRoomsTax[index].serviceCharge
        : item.serviceCharge

      return (
        <li key={index}>
          <span className="tax-list-item">
            Room {index + 1} - {item?.roomName}
          </span>
          <span>
            {currencyFormatter(amount, {
              significantDigits: 2,
              symbol: "Php",
            })}
          </span>
        </li>
      )
    })

  const addOnsTax = addOnsList
    .filter(item => item?.selected)
    .map((item, index) => {
      const vat = item.tax_breakup.filter(
        value => value.component_name === "VAT"
      )
      const amount = vat[0]?.amount

      return (
        <li key={index}>
          <span className="tax-list-item">{item?.name}</span>
          <span>
            {currencyFormatter(amount * item?.counter, {
              significantDigits: 2,
              symbol: "Php",
            })}
          </span>
        </li>
      )
    })

  const addOnsServiceCharge = addOnsList
    .filter(item => item?.selected)
    .map((item, index) => {
      const serviceCharge = item.tax_breakup.filter(
        value => value.component_name === "Service Charge"
      )
      const amount = serviceCharge[0]?.amount

      return (
        amount && (
          <li key={index}>
            <span className="tax-list-item">{item?.name}</span>
            <span>
              {currencyFormatter(amount * item?.counter, {
                significantDigits: 2,
                symbol: "Php",
              })}
            </span>
          </li>
        )
      )
    })

  return (
    <div>
      <Modal
        className="hotel-details-modal-wrapper"
        visible={visible}
        footer={false}
        onCancel={onCancel}
      >
        <h1 className="hotel-name">Taxes & Service Charge Breakup</h1>
        <Divider />
        <div>VAT</div>
        <ul className="tax-list">
          {roomsTaxList}
          {addOnsTax}
        </ul>
        <div>SERVICE CHARGE</div>
        <ul className="tax-list">
          {roomsServiceCharge}
          {addOnsServiceCharge}
        </ul>
      </Modal>
    </div>
  )
}

export default TaxesAndServiceDetails
